<template>
  <v-dialog v-model="dialog" max-width="800" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Invoice</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="6" lg="6" class="py-0">
                <v-subheader>Invoice #</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>{{ item.invoiceNumber }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="6" lg="6" class="py-0">
                <v-subheader>Invoice Date</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>{{ item.invoiceDate }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="6" lg="6" class="py-0">
                <v-subheader>Currency</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>{{ item.currency }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="6" lg="6" class="py-0">
                <v-subheader>Amount</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>{{
                  formatPrice(
                    item.totalRemainInvoice === 0
                      ? item.totalAmountAfterTax
                      : item.totalRemainInvoice
                  )
                }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="6" lg="6" class="py-0">
                <v-subheader>Rate (Invoice)</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>{{ item.rateInvoice }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="6" lg="6" class="py-0">
                <v-subheader>Total Invoice (RP)</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0" align-self="center">
                <span
                  >Rp.
                  {{
                    formatPrice(
                      item.totalRemainInvoice === 0 ? item.totalInvoiceIDR : item.totalRemainInvoice
                    )
                  }}</span
                >
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="6" lg="6" class="py-0">
                <v-subheader>Invoice Paid</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  dense
                  outlined
                  flat
                  type="number"
                  v-model.number="item.totalInvoicePaid"
                  @change="onInputInvoicePaid"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="6" lg="6" class="py-0">
                <v-subheader>Rate (Pay)</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  dense
                  outlined
                  flat
                  type="number"
                  v-model.number="item.ratePay"
                  @change="onInputRatePay"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="6" lg="6" class="py-0">
                <v-subheader>Total Paid (RP)</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>Rp. {{ formatPrice(item.totalInvoicePaidIDR) }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>PPh Psl 22</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="9" lg="9" class="py-0">
                <v-text-field
                  dense
                  outlined
                  flat
                  type="number"
                  v-model.number="item.pph22Amount"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>PPh Psl 23</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="9" lg="9" class="py-0">
                <v-text-field
                  dense
                  outlined
                  flat
                  type="number"
                  v-model.number="item.pph23Amount"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Penalty</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="9" lg="9" class="py-0">
                <v-text-field
                  dense
                  outlined
                  flat
                  type="number"
                  v-model.number="item.fineAmount"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-row>
              <v-col cols="4" sm="4" md="6" lg="6" class="py-0">
                <v-subheader>Full Payment</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-radio-group v-model="item.fullPayment" row @change="onChangeFullPayment">
                  <v-radio label="Yes" :value="true"></v-radio>
                  <v-radio label="No" :value="false"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="6" lg="6" class="py-0">
                <v-subheader>Pay For</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-select
                  outlined
                  flat
                  dense
                  v-model="item.payFor"
                  :items="listPayFor"
                  @change="onChangePayFor"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close">
          Close
        </v-btn>
        <v-btn color="red" text @click="deleteItem">
          Delete
        </v-btn>
        <v-btn color="primary" @click="save">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "dialog-sale-invoice",
  props: {
    dialog: Boolean,
    item: Object,
    index: Number,
  },
  data() {
    return {
      listPayFor: ["Sales", "Tax", "Total A/R"],
    };
  },
  methods: {
    deleteItem() {
      this.$emit("deleteItem", this.index);
    },
    save() {
      this.$emit("save", { index: this.index, item: this.item });
      this.$emit("close");
    },
    close() {
      this.$emit("close");
    },
    onInputRatePay() {
      this.item.totalInvoicePaidIDR = this.item.totalInvoicePaid * this.item.ratePay;
    },
    onInputInvoicePaid() {
      this.item.totalInvoicePaidIDR = this.item.totalInvoicePaid * this.item.ratePay;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    onChangeFullPayment(val) {
      if (val) {
        this.item.totalInvoicePaid =
          this.item.totalRemainInvoice === 0
            ? this.item.totalAmountAfterTax
            : this.item.totalRemainInvoice;
      } else {
        this.item.totalInvoicePaid = 0;
      }
      this.item.totalInvoicePaidIDR = this.item.totalInvoicePaid * this.item.ratePay;
    },
    onChangePayFor(val) {
      if (val === "Sales") {
        this.item.totalInvoicePaid = this.item.totalAmountAfterInvoice;
      } else if (val === "Tax") {
        this.item.totalInvoicePaid = this.item.taxAmount;
      } else {
        this.item.totalInvoicePaid =
          this.item.totalRemainInvoice === 0
            ? this.item.totalAmountAfterTax
            : this.item.totalRemainInvoice;
      }
      this.item.totalInvoicePaidIDR = this.item.totalInvoicePaid * this.item.ratePay;
    },
  },
};
</script>

<style></style>
