<template>
  <v-form ref="form" lazy-validation>
    <v-row>
      <v-col cols="12" sm="12" md="11" lg="11">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Customer <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.customerName"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Method<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-select
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.method"
                  :items="listMethod"
                  @change="onChangeMethod"
                  :rules="[v => !!v || 'Method is required']"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Pay Date<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-menu
                  v-model="menuPayDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="form.payDate"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.payDate"
                    :allowed-dates="getAllowedDates()"
                    @input="menuPayDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Invoice Number <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  append-icon="mdi-magnify"
                  item-text="name"
                  return-object
                  v-model="invoice"
                  :items="listInvoice"
                >
                  <template v-slot:item="data">
                    <v-list-item v-on="data.on" @click.stop="onChangeInvoice(data.item)">
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.invoiceNumber"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-row> -->
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Bank Account<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  append-icon="mdi-magnify"
                  item-text="accountName"
                  v-model="bank"
                  :items="listBank"
                  @change="onChangeBank"
                  return-object
                  :rules="[v => !!v || 'Bank is required']"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Total Paid (Bank)</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model.number="form.bankAmount"
                  prefix="Rp."
                  append-outer-icon="mdi-calculator"
                  @click:append-outer="calculation"
                  :rules="[v => v >= 0 || 'Total Paid must more than equal 0']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Bank Charges<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model.number="form.bankCharge"
                  prefix="Rp."
                  @change="onChangeBankCharge"
                  :rules="[v => v >= 0 || 'Bank charge must more than equals 0']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" v-if="form.method === 'Giro'">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Giro<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-btn color="success" @click="addGiro" class="mr-2">
                  <v-icon>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" v-if="form.method === 'Check'">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Check<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-btn color="success" @click="addCheck" class="mr-2">
                  <v-icon>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Pembulatan</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  prefix="Rp."
                  v-model.number="form.roundAmount"
                  :disabled="form.round === 'None'"
                  :rules="[v => v >= 0 || 'Round Amount must more than equals 0']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="3" lg="3" class="py-0">
                <v-select
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.round"
                  :items="listRound"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Remark</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-textarea
                  outlined
                  flat
                  rows="3"
                  background-color="white"
                  v-model="form.remark"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-tabs vertical>
              <v-tab background-color="light-blue accent-3" dark class="px-0">
                <v-icon>
                  mdi-note-text
                </v-icon>
              </v-tab>
              <v-tab>
                <v-icon>
                  mdi-information
                </v-icon>
              </v-tab>
              <v-tab v-if="form.method === 'Giro'">
                <v-icon>
                  mdi-information
                </v-icon>
              </v-tab>
              <v-tab v-if="form.method === 'Check'">
                <v-icon>
                  mdi-information
                </v-icon>
              </v-tab>
              <v-tab-item>
                <v-card flat class="px-2">
                  <v-row>
                    <v-col cols="6">
                      <v-autocomplete
                        outlined
                        flat
                        dense
                        background-color="white"
                        append-icon="mdi-magnify"
                        item-text="invoiceNumber"
                        return-object
                        v-model="invoice"
                        :items="listInvoice"
                        :rules="[v => !!v || 'Invoice is required']"
                      >
                        <template v-slot:item="data">
                          <v-list-item v-on="data.on" @click.stop="onChangeInvoice(data.item)">
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="data.item.invoiceNumber"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="6" align-self="start" class="text-right pb-0">
                      <p class="headline font-italic">
                        Invoice Detail <span class="red--text">*</span>
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headers"
                        :items="form.invoices"
                        disable-pagination
                        hide-default-footer
                        @click:row="onClickRow"
                      >
                        <template v-slot:item.no="{ item }">
                          {{ form.invoices.map(x => x).indexOf(item) + 1 }}
                        </template>
                        <template v-slot:item.totalAmountAfterTax="{ item }">
                          {{ formatPrice(item.totalAmountAfterTax) }}
                        </template>
                        <template v-slot:item.rateInvoice="{ item }">
                          {{ formatPrice(item.rateInvoice) }}
                        </template>
                        <template v-slot:item.totalInvoiceIDR="{ item }">
                          {{ formatPrice(item.totalInvoiceIDR) }}
                        </template>
                        <template v-slot:item.ratePay="{ item }">
                          {{ formatPrice(item.ratePay) }}
                        </template>
                        <template v-slot:item.totalInvoicePaid="{ item }">
                          {{ formatPrice(item.totalInvoicePaid) }}
                        </template>
                        <template v-slot:item.totalInvoicePaidIDR="{ item }">
                          {{ formatPrice(item.totalInvoicePaidIDR) }}
                        </template>
                        <template v-slot:item.pph22Amount="{ item }">
                          {{ formatPrice(item.pph22Amount) }}
                        </template>
                        <template v-slot:item.pph23Amount="{ item }">
                          {{ formatPrice(item.pph23Amount) }}
                        </template>
                        <template v-slot:item.fineAmount="{ item }">
                          {{ formatPrice(item.fineAmount) }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-title class="font-italic">Additional Info</v-card-title>
                  <v-row>
                    <v-col cols="4" sm="4" md="2" lg="2" class="pb-0">
                      <v-subheader>Bill To</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4" class="pb-0">
                      <span>{{ form.billTo }}</span>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
              <v-tab-item v-if="form.method === 'Giro'">
                <v-card flat class="px-2">
                  <v-row>
                    <v-col cols="6"> </v-col>
                    <v-col cols="6" align-self="start" class="text-right pb-0">
                      <p class="headline font-italic">Giro <span class="red--text">*</span></p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headerGiro"
                        :items="form.giros"
                        disable-pagination
                        hide-default-footer
                        @click:row="onClickRowGiro"
                      >
                        <template v-slot:item.no="{ index }">
                          {{ index + 1 }}
                        </template>
                        <template v-slot:item.amount="{ item }">
                          {{ formatPrice(item.amount) }}
                        </template>
                        <template v-slot:item.action="{ index }">
                          <v-btn icon color="red" @click="deleteMethod(index)">
                            <v-icon>
                              mdi-delete
                            </v-icon>
                          </v-btn>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
              <v-tab-item v-if="form.method === 'Check'">
                <v-card flat class="px-2">
                  <v-row>
                    <v-col cols="6"> </v-col>
                    <v-col cols="6" align-self="start" class="text-right pb-0">
                      <p class="headline font-italic">Check <span class="red--text">*</span></p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headerCheck"
                        :items="form.checks"
                        disable-pagination
                        hide-default-footer
                        @click:row="onClickRowCheck"
                      >
                        <template v-slot:item.no="{ index }">
                          {{ index + 1 }}
                        </template>
                        <template v-slot:item.amount="{ item }">
                          {{ formatPrice(item.amount) }}
                        </template>
                        <template v-slot:item.action="{ index }">
                          <v-btn icon color="red" @click="deleteMethod(index)">
                            <v-icon>
                              mdi-delete
                            </v-icon>
                          </v-btn>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-sale-invoice
      :item="item"
      :index="index"
      :dialog="dialog"
      @close="close"
      @save="addItem"
      @deleteItem="deleteItem"
    ></dialog-sale-invoice>
    <dialog-method
      :dialog="dialogMethod"
      :item="methodItem"
      @save="saveMethod"
      @close="close"
      :type="form.method"
    ></dialog-method>
    <dialog-review-journal
      :dialog="dialogReview"
      :journals="journals"
      @save="update"
      @close="close"
      currency="IDR"
    ></dialog-review-journal>
  </v-form>
</template>

<script>
import DialogSaleInvoice from "@/components/DialogSaleInvoice";
import DialogMethod from "@/components/DialogMethod";
import DialogReviewJournal from "@/components/DialogReviewJournal";
import { fiscalMonthMixin } from "@/services/mixins/fiscalMonthMixin";

export default {
  name: "form-sale-payment",
  mixins: [fiscalMonthMixin],
  components: {
    "dialog-sale-invoice": DialogSaleInvoice,
    DialogMethod,
    DialogReviewJournal,
  },
  props: {
    form: Object,
    listInvoice: Array,
    listBank: Array,
    listInvoiceDp: Array,
    customers: Array,
  },
  data: () => ({
    menuPayDate: false,
    dialog: false,
    dialogMethod: false,
    dialogReview: false,
    item: {},
    invoice: {},
    invoiceDp: {},
    index: -1,
    methodItem: {},
    journals: [],
    headers: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Invoice #",
        value: "invoiceNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "Invoice Date",
        value: "invoiceDate",
        sortable: false,
        divider: true,
      },
      {
        text: "Currency",
        value: "currency",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount",
        value: "totalAmountAfterTax",
        sortable: false,
        divider: true,
      },
      {
        text: "Rate (Invoice)",
        value: "rateInvoice",
        sortable: false,
        divider: true,
      },
      {
        text: "Total Invoice (RP)",
        value: "totalInvoiceIDR",
        sortable: false,
        divider: true,
      },
      {
        text: "Rate (Pay)",
        value: "ratePay",
        sortable: false,
        divider: true,
      },
      {
        text: "Invoice Paid",
        value: "totalInvoicePaid",
        sortable: false,
        divider: true,
      },
      {
        text: "Total Paid (RP)",
        value: "totalInvoicePaidIDR",
        sortable: false,
        divider: true,
      },
      {
        text: "PPh Psl 22",
        value: "pph22Amount",
        sortable: false,
        divider: true,
      },
      {
        text: "PPh Psl 23",
        value: "pph23Amount",
        sortable: false,
        divider: true,
      },
      {
        text: "Penalty",
        value: "fineAmount",
        sortable: false,
        divider: true,
      },
      {
        text: "Full Payment",
        value: "fullPayment",
        sortable: false,
        divider: true,
      },
      {
        text: "Pay For",
        value: "payFor",
        sortable: false,
        divider: true,
      },
    ],
    listMethod: ["TT", "Check", "Cash", "Giro", "Transfer"],
    bank: {},
    listRound: ["None", "UP", "DOWN"],
  }),

  computed: {
    total() {
      let totalPaid = 0;
      this.form.invoices.forEach(x => {
        totalPaid += x.totalInvoicePaid;
      });
      return totalPaid;
    },
    totalPaid() {
      let total = 0;
      this.form.invoices.forEach(x => {
        if (x.fullPayment) {
          total += x.totalInvoicePaidIDR;
        }
      });
      return parseFloat(total.toFixed(2));
    },
    totalPph23() {
      let total = 0;
      this.form.invoices.forEach(x => {
        if (x.fullPayment) {
          total += x.pph23Amount;
        }
      });
      return parseFloat(total.toFixed(2));
    },
  },

  methods: {
    checkJournal() {
      if (this.$refs.form.validate() && this.form.invoices.length > 0) {
        let totalPph22 = 0;
        let totalPph23 = 0;
        let penalty = 0;
        let totalPaid = 0;
        this.form.invoices.forEach(x => {
          totalPph22 += x.pph22Amount;
          totalPph23 += x.pph23Amount;
          penalty += x.fineAmount;
          totalPaid += x.totalInvoicePaid;
        });
        this.journals = [
          {
            accountName: `${this.bank.accountName}`,
            debit: this.form.bankAmount,
            credit: 0,
          },
          {
            accountName: `${this.form.customerName} IDR`,
            debit: 0,
            credit: totalPaid,
          },
        ];
        if (this.form.bankCharge > 0) {
          this.journals.unshift({
            accountName: `BIAYA ADMINISTRASI BANK`,
            debit: this.form.bankCharge,
            credit: 0,
          });
        }
        if (totalPph22 > 0) {
          this.journals.unshift({
            accountName: `PAJAK PPH 22`,
            debit: totalPph22,
            credit: 0,
          });
        }
        if (totalPph23 > 0) {
          this.journals.unshift({
            accountName: `PAJAK PPH 23`,
            debit: totalPph23,
            credit: 0,
          });
        }
        if (penalty > 0) {
          this.journals.unshift({
            accountName: `BIAYA DENDA`,
            debit: penalty,
            credit: 0,
          });
        }
        if (this.form.round === "UP") {
          this.journals.unshift({
            accountName: "SELISIH KURS",
            debit: this.form.roundAmount,
            credit: 0,
          });
        } else if (this.form.round === "DOWN") {
          this.journals.push({
            accountName: "SELISIH KURS",
            debit: 0,
            credit: this.form.roundAmount,
          });
        }
        this.dialogReview = true;
      } else {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Items is required",
        });
      }
    },
    async update() {
      let totalDebit = this.form.bankAmount + this.form.bankCharge;
      let totalCredit = 0;

      this.form.invoices.forEach(x => {
        totalDebit += x.fineAmount + x.pph22Amount + x.pph23Amount;
      });

      this.form.invoices.forEach(x => {
        totalCredit += x.totalInvoicePaid;
      });

      if (this.form.round === "UP") {
        totalDebit += this.form.roundAmount;
      } else if (this.form.round === "DOWN") {
        totalCredit += this.form.roundAmount;
      }

      if (totalDebit - totalCredit === 0) {
        this.$store.commit("SET_LOADER", true);
        await this.$store
          .dispatch("salePayment/create", { body: this.form, type: "update" })
          .then(() => this.$store.commit("SET_LOADER", false))
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      } else {
        let different = totalDebit - totalCredit;
        if (different > 0) {
          this.$store.dispatch("toast", {
            type: "error",
            message: `Journal not balance with -${Math.abs(different)} on Debit`,
          });
        } else {
          this.$store.dispatch("toast", {
            type: "error",
            message: `Journal not balance with -${Math.abs(different)} on Dredit`,
          });
        }
      }
    },
    onChangeInvoice(item) {
      this.form.invoices.push(item);
      this.item = item;
      this.index = this.invoices.map(x => x).indexOf(item);
      this.dialog = true;
    },
    onClickRow(item) {
      this.item = item;
      this.index = this.form.invoices.map(x => x).indexOf(item);
      this.dialog = true;
    },
    onClickRowCheck(item) {
      this.methodItem = item;
      this.index = this.form.checks.indexOf(item);
      this.dialogMethod = true;
    },
    onClickRowGiro(item) {
      this.methodItem = item;
      this.index = this.form.giros.indexOf(item);
      this.dialogMethod = true;
    },
    addItem(data) {
      this.form.invoices[data.index] = data.item;
    },
    calculation() {
      if (this.form.invoices.length > 0) {
        if (this.totalPaid > 0) {
          let total = parseFloat(
            (this.totalPaid - (this.form.bankAmount + this.totalPph23)).toFixed(2)
          );
          if (total > 0) {
            this.form.round = "UP";
            this.form.roundAmount = total;
          } else if (total < 0) {
            this.form.round = "DOWN";
            this.form.roundAmount = Math.abs(total);
          } else {
            this.form.round = "None";
            this.form.roundAmount = 0;
          }
        }
      } else {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Invoice is Required",
        });
      }
    },
    onChangeBank(val) {
      this.form.bankId = val.accountId;
    },
    onChangeBankCharge(val) {
      if (this.form.bankAmount > 0) {
        this.form.bankAmount = this.total;
        this.form.bankAmount -= val;
      }
    },
    onChangeMethod(val) {
      if (val !== "Giro" || val !== "Check") {
        this.methodItem = {};
        this.form.giros = [];
        this.form.check = [];
      }
    },
    addGiro() {
      this.methodItem = {
        giroNumber: "",
        bankName: "",
        amount: 0,
      };
      this.typeMethod = "Giro";
      this.dialogMethod = true;
    },
    addCheck() {
      this.methodItem = {
        checkNumber: "",
        bankName: "",
        amount: 0,
      };
      this.typeMethod = "Check";
      this.dialogMethod = true;
    },
    saveMethod() {
      if (this.typeMethod === "Giro") {
        if (this.form.giros.length > 0) {
          this.form.giros[this.index] = this.methodItem;
        } else {
          this.form.giros.push(this.methodItem);
        }
      } else {
        if (this.form.checks.length > 0) {
          this.form.checks[this.index] = this.methodItem;
        } else {
          this.form.checks.push(this.methodItem);
        }
      }
    },
    deleteMethod(index) {
      if (index > -1) {
        if (this.typeMethod === "Giro") {
          this.form.giros.splice(index, 1);
        } else {
          this.form.checks.splice(index, 1);
        }
      }
    },
    close() {
      this.dialog = false;
      this.dialogMethod = false;
      this.dialogReview = false;
    },
    deleteItem(index) {
      if (index > -1) {
        if (this.form.invoices[index].id != null) {
          this.form.invoices[index].deleted = true;
        }
        this.form.invoices.splice(index, 1);
        this.dialog = false;
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getBank() {
      this.bank = this.listBank.filter(x => x.accountId === this.form.bankId)[0];
    },
  },
  mounted() {
    this.getBank();
  },
};
</script>

<style></style>
