<template>
  <v-dialog v-model="dialog" width="700" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Cancel Sale Payment</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" sm="12" md="3" lg="2" class="py-0">
                <v-subheader>Remark</v-subheader>
              </v-col>
              <v-col cols="12" sm="12" md="9" lg="10">
                <v-textarea
                  flat
                  outlined
                  background-color="white"
                  rows="3"
                  v-model="remark"
                  :rules="[v => !!v || 'Remark is required']"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close">
          Close
        </v-btn>
        <v-btn color="primary" @click="save">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "dialog-cancel",
  props: {
    dialog: Boolean,
  },
  data() {
    return {
      remark: "",
    };
  },
  methods: {
    save() {
      this.$emit("save", this.remark);
      this.$emit("close");
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style></style>
