<template>
  <form>
    <v-row>
      <v-col cols="12" sm="12" md="11" lg="11">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Customer <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{ form.customerName }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Voucher Number <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{ form.voucherNumber }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Method<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{ form.method }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Pay Date<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{ formatDate(form.payDate) }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Bank Account<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{ bankName(form.bankId) }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Total Paid (Bank)</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{ formatPrice(form.bankAmount) }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Pembulatan</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{ formatPrice(form.roundAmount) }}</span>
              </v-col>
              <v-col cols="12" sm="12" md="3" lg="3">
                <span>{{ form.round }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Bank Charges<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{ formatPrice(form.bankCharge) }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Remark</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{ form.remark }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-tabs vertical>
              <v-tab background-color="light-blue accent-3" dark class="px-0">
                <v-icon>
                  mdi-note-text
                </v-icon>
              </v-tab>
              <v-tab>
                <v-icon>
                  mdi-information
                </v-icon>
              </v-tab>
              <v-tab-item>
                <v-card flat class="px-2">
                  <v-row>
                    <v-col cols="6"></v-col>
                    <v-col cols="6" align-self="start" class="text-right pb-0">
                      <p class="headline font-italic">
                        Invoice Detail <span class="red--text">*</span>
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headers"
                        :items="form.invoices"
                        disable-pagination
                        hide-default-footer
                      >
                        <template v-slot:item.no="{ item }">
                          {{ form.invoices.map(x => x).indexOf(item) + 1 }}
                        </template>
                        <template v-slot:item.totalAmountAfterTax="{ item }">
                          {{ formatPrice(item.totalAmountAfterTax) }}
                        </template>
                        <template v-slot:item.rateInvoice="{ item }">
                          {{ formatPrice(item.rateInvoice) }}
                        </template>
                        <template v-slot:item.totalInvoiceIDR="{ item }">
                          {{ formatPrice(item.totalInvoiceIDR) }}
                        </template>
                        <template v-slot:item.ratePay="{ item }">
                          {{ formatPrice(item.ratePay) }}
                        </template>
                        <template v-slot:item.totalInvoicePaid="{ item }">
                          {{ formatPrice(item.totalInvoicePaid) }}
                        </template>
                        <template v-slot:item.totalInvoicePaidIDR="{ item }">
                          {{ formatPrice(item.totalInvoicePaidIDR) }}
                        </template>
                        <template v-slot:item.pph22Amount="{ item }">
                          {{ formatPrice(item.pph22Amount) }}
                        </template>
                        <template v-slot:item.pph23Amount="{ item }">
                          {{ formatPrice(item.pph23Amount) }}
                        </template>
                        <template v-slot:item.fineAmount="{ item }">
                          {{ formatPrice(item.fineAmount) }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-title class="font-italic">Additional Info</v-card-title>
                  <v-row>
                    <v-col cols="4" sm="4" md="2" lg="2" class="pb-0">
                      <v-subheader>Bill To</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4">
                      <span>{{ form.billTo }}</span>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </form>
</template>

<script>
import moment from "moment";
export default {
  name: "detail-sale-payment",
  props: {
    form: Object,
    id: Number,
    listBank: Array,
  },
  data: () => ({
    headers: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Invoice #",
        value: "invoiceNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "Invoice Date",
        value: "invoiceDate",
        sortable: false,
        divider: true,
      },
      {
        text: "Currency",
        value: "currency",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount",
        value: "totalAmountAfterTax",
        sortable: false,
        divider: true,
      },
      {
        text: "Rate (Invoice)",
        value: "rateInvoice",
        sortable: false,
        divider: true,
      },
      {
        text: "Total Invoice (RP)",
        value: "totalInvoiceIDR",
        sortable: false,
        divider: true,
      },
      {
        text: "Rate (Pay)",
        value: "ratePay",
        sortable: false,
        divider: true,
      },
      {
        text: "Invoice Paid",
        value: "totalInvoicePaid",
        sortable: false,
        divider: true,
      },
      {
        text: "Total Paid (RP)",
        value: "totalInvoicePaidIDR",
        sortable: false,
        divider: true,
      },
      {
        text: "PPh Psl 22",
        value: "pph22Amount",
        sortable: false,
        divider: true,
      },
      {
        text: "PPh Psl 23",
        value: "pph23Amount",
        sortable: false,
        divider: true,
      },
      {
        text: "Fine",
        value: "fineAmount",
        sortable: false,
        divider: true,
      },
      {
        text: "Full Payment",
        value: "fullPayment",
        sortable: false,
        divider: true,
      },
      {
        text: "Pay For",
        value: "payFor",
        sortable: false,
        divider: true,
      },
    ],
  }),

  methods: {
    bankName(id) {
      const bank = this.listBank.find(x => x.accountId === id);
      const name = bank ? bank.accountName : "";
      return name;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatDate(date) {
      return moment(date).format("DD-MM-yyyy");
    },
  },
};
</script>

<style></style>
